import { useEffect, useState } from "react";
import { Checkbox, Label, Radio, TextInput } from 'flowbite-react';
import { getDataOwnerNameByLogisticsObjectURI } from "../../utils";


export default function LoadingForm(props) {

    const type = "https://onerecord.iata.org/ns/cargo#Loading";

    const [id, setId] = useState("");
    const [loadedUnits, setLoadedUnits] = useState([])
    const [transportMeans, setTransportMeans] = useState(null);


    const [idColor, setIdColor] = useState(null);    
    const [loadedUnitsColor, setLoadedUnitsColor] = useState(null);
    const [transportMeansColor, setTransportMeansColor] = useState(null);

    const [availableLoadingUnits, setAvailableLoadingUnits] = useState(null);
    const [availableTransportMeans, setAvailableTransportMeans] = useState(null);


    const [error, setError] = useState(null);

    useEffect(() => {
        let logisticsObject = {
            "@type": type,
            "@id": id,            
            "loadedUnits": loadedUnits,
            "onTransportMeans": transportMeans
        };
        props.setLogisticsObject(logisticsObject);
        props.setJsonLD(logisticsObject);
    }, [id, loadedUnits, transportMeans]);

    useEffect(() => {
        if (availableLoadingUnits === null) {
            fetch("https://index.one-record.de/logistics-objects?logisticsObjectTypes=https%3A%2F%2Fonerecord.iata.org%2Fns%2Fcargo%23Uld", {
                method: "GET"
            })
                .then(response => response.json())
                .then(data => {
                    setAvailableLoadingUnits(data);
                }
                );
        }

    }, [availableLoadingUnits]);

    useEffect(() => {
        if (availableTransportMeans === null) {
            fetch("https://index.one-record.de/logistics-objects?logisticsObjectTypes=https%3A%2F%2Fonerecord.iata.org%2Fns%2Fcargo%23TransportMeans", {
                method: "GET"
            })
                .then(response => response.json())
                .then(data => {
                    setAvailableTransportMeans(data);
                }
                );
        }

    }, [availableTransportMeans]);





    return (
        <>
            <form className="flex max-w-md flex-col gap-4">
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="id"
                            value="ID*"
                        />
                    </div>
                    <TextInput
                        id="id"
                        helperText={<>This MUST be a unique id, allowed characters: a-z, 0-9, -</>}
                        required
                        shadow
                        type="text"
                        value={id}
                        color={idColor}
                        onChange={(e) => {
                            e.preventDefault();
                            //replace uppercase with lowercase
                            let sanitized = e.target.value.toLowerCase();
                            // replace whitespace with dash     
                            sanitized = sanitized.replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
                            setId(sanitized);
                        }}
                        onBlur={(e) => {
                            e.preventDefault();
                            // if multiple dashes in a row, replace with single dash
                            let sanitized = e.target.value.replace(/-+/g, '-');
                            //replace last dash with whitespace                            
                            sanitized = sanitized.replace(/-$/, ' ');
                            setId(sanitized);
                        }}
                    />
                </div>

                {/* OnTransportMeans */}
                <div>
                    <fieldset className="flex max-w-md flex-col gap-4" id="TransportMeans" onChange={(e) => { setTransportMeans(e.target.value); }}>
                        <legend className="mb-4">
                            Transport Means*
                        </legend>
                        {availableTransportMeans !== null && availableTransportMeans.length === 0 && <div className="text-red-600 text-sm">No TransportMeans found.</div>}

                        {availableTransportMeans?.map((availableTransportMean) => (
                            <div className="flex items-center gap-2" key={availableTransportMean["@id"]}>
                                <Radio
                                    id={availableTransportMean['@id']}
                                    value={availableTransportMean['@id']}
                                    name="TransportMeans"
                                    checked={availableTransportMean['@id'] === transportMeans}
                                />
                                <Label htmlFor={availableTransportMean['@id']} className="font-normal">
                                    {availableTransportMean['@id'].split("/logistics-objects/").pop()} @ {getDataOwnerNameByLogisticsObjectURI(availableTransportMean['@id'])}
                                </Label>

                            </div>
                        ))}
                    </fieldset>
                </div>
                <div>{transportMeansColor !== null && <div className="text-red-600 text-sm">Select one ULD.</div>}</div>

                {/* loadedUnits */}
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="loadingUnits" value="ULDs*"/>
                    </div>

                    {availableLoadingUnits !== null && availableLoadingUnits.length === 0 && <div className="text-red-600 text-sm">No ULDs found.</div>}

                    {availableLoadingUnits?.map((availableLoadingUnit) => (
                        <div key={availableLoadingUnit["@id"]} className="flex items-center gap-2">
                            <Checkbox id={availableLoadingUnit["@id"]} onChange={(e) => {
                                if (e.target.checked) {
                                    setLoadedUnits([...loadedUnits, availableLoadingUnit["@id"]]);
                                } else {
                                    setLoadedUnits(loadedUnits.filter((item) => item !== availableLoadingUnit["@id"]));
                                }
                            }}
                            />
                            <Label htmlFor={availableLoadingUnit["@id"]}>
                                {availableLoadingUnit["@id"].split("/logistics-objects/").pop()} @ {getDataOwnerNameByLogisticsObjectURI(availableLoadingUnit["@id"])}
                            </Label>
                        </div>
                    ))}
                    {loadedUnitsColor !== null && <div className="text-red-600 text-sm">Select ULD.</div>}
                </div>



                {error && <div className="text-red-600 text-sm">{error}</div>}

                <button disabled={availableLoadingUnits === null || availableLoadingUnits.length === 0 || availableTransportMeans === null || availableTransportMeans.length === 0} type={"submit"} onClick={(e) => {
                    e.preventDefault();
                    if (id === "" || transportMeans === null || loadedUnits === null || loadedUnits.length === 0) {
                        if (id === "") {
                            setIdColor("failure");
                        }
                        if (transportMeans === null) {
                            setTransportMeansColor("failure");
                        }
                        if (loadedUnits === null || loadedUnits.length === 0) {
                            setLoadedUnitsColor("failure");
                        }
                        
                        setError("Please fill in all required");
                    } else {
                        setIdColor(null);
                        setTransportMeansColor(null);
                        setLoadedUnitsColor(null);
                        
                        setError(null);

                        props.createLogisticsObject().then((result) => {
                            if (result !== "Error") {
                                setId("");
                                setTransportMeans(null);
                                setLoadedUnits([]);

                            }
                        });
                    }
                }}
                    className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                    Create
                </button>
            </form >

        </>

    );
}
