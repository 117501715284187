import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

function CustomNode({ data }) {
  return (
    <div className={`px-4 py-2 shadow-md rounded-lg bg-white border-4 border-${data.color}-700`} style={{ width: '195px' }}>

      <Handle type="source" position={Position.Right} />
      <Handle type="target" position={Position.Left} />      

      <Handle type="source" id="sourceLeft" position={Position.Left} />      
      <Handle type="target" id="targetLeft" position={Position.Left} />      
      

      <Handle type="target" id="targetRight" position={Position.Right} />      
      
      <Handle type="source" id="sourceTop" position={Position.Top} />
      <Handle type="target" id="targetTop" position={Position.Top} />
      

      <Handle type="source" id="sourceBottom" position={Position.Bottom} />            
      <Handle type="target" id="targetBottom" position={Position.Bottom} />      

      <div className="flex overflow-hidden">
        <div className="ml-2">
        <div className="text-sm font-bold">{data.logisticsObjectType}</div>
          <div className="text-gray-500 truncate">{data.logisticsObjectId}</div>          
        </div>
      </div>
    </div>
  );
}

export default memo(CustomNode);
