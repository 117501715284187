import { useEffect, useState } from "react";
import { Button, Checkbox, Label, Radio, TextInput, Textarea } from 'flowbite-react';
import { getDataOwnerNameByLogisticsObjectURI } from "../../utils";


export default function UnitCompositionForm(props) {

    const type = "https://onerecord.iata.org/ns/cargo#UnitComposition";

    const [id, setId] = useState("");
    // const [compositionIdentifier, setCompositionIdentifier] = useState("");
    const [composedPieces, setComposedPieces] = useState([])
    const [loadingUnit, setLoadingUnit] = useState(null);


    const [idColor, setIdColor] = useState(null);
    //const [compositionIdentifierColor, setCompositionIdentifierColor] = useState(null);
    const [composedPiecesColor, setComposedPiecesColor] = useState(null);
    const [loadingUnitColor, setLoadingUnitColor] = useState(null);

    const [availablePieces, setAvailablePieces] = useState(null);
    const [availableLoadingUnits, setAvailableLoadingUnits] = useState(null);


    const [error, setError] = useState(null);

    useEffect(() => {
        let logisticsObject = {
            "@type": type,
            "@id": id,
            // "compositionIdentifier": compositionIdentifier,
            "composedPieces": composedPieces,
            "loadingUnit": loadingUnit
        };
        props.setLogisticsObject(logisticsObject);
        props.setJsonLD(logisticsObject);
    }, [id, composedPieces, loadingUnit]);

    useEffect(() => {
        if (availablePieces === null) {
            fetch("https://index.one-record.de/logistics-objects?logisticsObjectTypes=https%3A%2F%2Fonerecord.iata.org%2Fns%2Fcargo%23Piece", {
                method: "GET"
            })
                .then(response => response.json())
                .then(data => {
                    setAvailablePieces(data);
                }
                );
        }

    }, [availablePieces]);

    useEffect(() => {
        if (availableLoadingUnits === null) {
            fetch("https://index.one-record.de/logistics-objects?logisticsObjectTypes=https%3A%2F%2Fonerecord.iata.org%2Fns%2Fcargo%23Uld", {
                method: "GET"
            })
                .then(response => response.json())
                .then(data => {
                    setAvailableLoadingUnits(data);
                }
                );
        }

    }, [availableLoadingUnits]);





    return (
        <>
            <form className="flex max-w-md flex-col gap-4">
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="id"
                            value="ID*"
                        />
                    </div>
                    <TextInput
                        id="id"
                        helperText={<>This MUST be a unique id, allowed characters: a-z, 0-9, -</>}
                        required
                        shadow
                        type="text"
                        value={id}
                        color={idColor}
                        onChange={(e) => {
                            e.preventDefault();
                            //replace uppercase with lowercase
                            let sanitized = e.target.value.toLowerCase();
                            // replace whitespace with dash     
                            sanitized = sanitized.replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
                            setId(sanitized);
                        }}
                        onBlur={(e) => {
                            e.preventDefault();
                            // if multiple dashes in a row, replace with single dash
                            let sanitized = e.target.value.replace(/-+/g, '-');
                            //replace last dash with whitespace                            
                            sanitized = sanitized.replace(/-$/, ' ');
                            setId(sanitized);
                        }}
                    />
                </div>

                {/* <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="compositionIdentifier"
                            value="Composition Identifier"
                        />
                    </div>
                    <TextInput
                        id="compositionIdentifier"
                        shadow
                        required
                        color={compositionIdentifierColor}
                        type="text"                        
                        value={compositionIdentifier}
                        onChange={(e) => {                            
                            setCompositionIdentifier(e.target.value);
                        }}
                        onBlur={(e) => {
                            
                            setCompositionIdentifier(e.target.value);
                        }}
                    />
                </div> */}

                {/* LoadingUnit */}
                <div>
                    <fieldset className="flex max-w-md flex-col gap-4" id="loadingUnit" onChange={(e) => { setLoadingUnit(e.target.value); }}>
                        <legend className="mb-4">
                            ULD*
                        </legend>
                        {availableLoadingUnits !== null && availableLoadingUnits.length === 0 && <div className="text-red-600 text-sm">No ULDs found.</div>}

                        {availableLoadingUnits?.map((availableLoadingUnit) => (
                            <div className="flex items-center gap-2" key={availableLoadingUnit["@id"]}>
                                <Radio
                                    id={availableLoadingUnit['@id']}
                                    value={availableLoadingUnit['@id']}
                                    name="loadingUnit"
                                    checked={availableLoadingUnit['@id'] === loadingUnit}
                                />
                                <Label htmlFor={availableLoadingUnit['@id']} className="font-normal">
                                    {availableLoadingUnit['@id'].split("/logistics-objects/").pop()} @ {getDataOwnerNameByLogisticsObjectURI(availableLoadingUnit['@id'])}
                                </Label>

                            </div>
                        ))}
                    </fieldset>
                </div>
                <div>{loadingUnitColor !== null && <div className="text-red-600 text-sm">Select one ULD.</div>}</div>

                {/* ComposedPieces */}
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="pieces" value="Pieces*"/>
                    </div>

                    {availablePieces !== null && availablePieces.length === 0 && <div className="text-red-600 text-sm">No pieces found.</div>}

                    {availablePieces?.map((piece) => (
                        <div key={piece["@id"]} className="flex items-center gap-2">
                            <Checkbox id={piece["@id"]} onChange={(e) => {
                                if (e.target.checked) {
                                    setComposedPieces([...composedPieces, piece["@id"]]);
                                } else {
                                    setComposedPieces(composedPieces.filter((item) => item !== piece["@id"]));
                                }
                            }}
                            />
                            <Label htmlFor={piece["@id"]}>
                                {piece["@id"].split("/logistics-objects/").pop()} @ {getDataOwnerNameByLogisticsObjectURI(piece["@id"])}
                            </Label>
                        </div>
                    ))}
                    {composedPiecesColor !== null && <div className="text-red-600 text-sm">Select at least one piece.</div>}
                </div>



                {error && <div className="text-red-600 text-sm">{error}</div>}

                <button disabled={availablePieces === null || availablePieces.length === 0 || availableLoadingUnits === null || availableLoadingUnits.length === 0} type={"submit"} onClick={(e) => {
                    e.preventDefault();
                    if (id === "" || loadingUnit === null || composedPieces === null || composedPieces.length === 0) {
                        if (id === "") {
                            setIdColor("failure");
                        }
                        // if (compositionIdentifier === "") {
                        //     setCompositionIdentifierColor("failure");
                        // }
                        if (loadingUnit === null) {
                            setLoadingUnitColor("failure");
                        }
                        if (composedPieces === null || composedPieces.length === 0) {
                            setComposedPiecesColor("failure");
                        }
                        
                        setError("Please fill in all required");
                    } else {
                        setIdColor(null);
                        // setCompositionIdentifierColor(null);
                        setLoadingUnitColor(null);
                        setComposedPiecesColor(null);
                        

                        setError(null);

                        props.createLogisticsObject().then((result) => {
                            if (result !== "Error") {
                                setId("");
                                // setCompositionIdentifier("");
                                setLoadingUnit(null);
                                setComposedPieces([]);
                            }
                        });
                    }
                }}
                    className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                    Create
                </button>
            </form >

        </>

    );
}
