import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getColorByLogisticsObjectURI, getDataOwnerNameByLogisticsObjectURI } from '../utils';
import { Dropdown } from 'flowbite-react';

function toTitleCase(text) {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export default function LogisticsObjects(props) {

  var oneRecordIndexBase = "https://index.one-record.de";

  const logisticsObjectsURI = `${oneRecordIndexBase}/logistics-objects`;
  const logisticsObjectsFilterOptionsURI = `${oneRecordIndexBase}/logistics-objects/filter-options`;
  const [logisticsObjects, setLogisticsObjects] = useState(null);
  const [filterOptions, setFilterOptions] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (logisticsObjects === null || logisticsObjects.length === 0) {
      setLoading(true);
    }
    fetch(logisticsObjectsFilterOptionsURI)
      .then(response => response.json())
      .then((data) => {
        setFilterOptions(data);
      }).catch(error => {
        console.log(error);
      });

    let url = `${logisticsObjectsURI}`;
    if ('logisticsObjectTypes' in selectedFilters && selectedFilters.logisticsObjectTypes.length > 0) {
      url = `${logisticsObjectsURI}?logisticsObjectTypes=${encodeURIComponent(selectedFilters.logisticsObjectTypes[0])}`;
    }
    fetch(url)
      .then(response => response.json())
      .then((data) => {
        //order by Last-Modified key
        data.sort((a, b) => (a['Last-Modified'] > b['Last-Modified'] ? -1 : 1));
        setLogisticsObjects(data);
        setLoading(false);
      }).catch(error => {
        console.log(error);
      });
  }, [selectedFilters]);

  if (isLoading) return <p>Loading...</p>;

  function isActiveFilter(selectedFilters, key) {
    return selectedFilters !== undefined && selectedFilters !== null && 'logisticsObjectTypes' in selectedFilters && selectedFilters.logisticsObjectTypes.includes(key);
  }

  function renderTimeSinceLastUpdate(lastUpdatedTime) {
    // calculate seconds between lastUpdatedTime and now
    const seconds = Math.floor((new Date() - new Date(lastUpdatedTime)) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (seconds < 60)
      return (<>{seconds} seconds ago</>)
    else if (minutes < 60)
      return (<>{minutes} minutes ago</>)
    else if (hours < 24)
      return (<>{hours} hours ago</>)
    else
      return (<>{days} days ago</>)
  }

  return (
    <>
      <div className="p-4 sm:ml-4 sm:mr-8">
        <div className="flex justify-start pb-3 space-x-5">

          {filterOptions !== null && (
            <div className="">
              <Dropdown color={'gray'} label="Filters">
                {Object.keys(filterOptions).sort((a, b) => (a > b ? 1 : -1)).map((key) => (
                  <Dropdown.Item onClick={() => {
                    if (isActiveFilter(selectedFilters, key)) {
                      // reset filter
                      setSelectedFilters({
                        logisticsObjectTypes: [],
                      });
                    } else {
                      setSelectedFilters({
                        logisticsObjectTypes: [key],
                      });
                    }
                  }} className={isActiveFilter(selectedFilters, key) ? 'font-semibold' : ''}>
                    {toTitleCase(key.split('#').pop())} ({filterOptions[key]})
                  </Dropdown.Item>
                ))}
              </Dropdown>
            </div>
          )}

          <div>
            <Dropdown color={'gray'} arrowIcon={false}
              label="Create"
            >
              {props?.selectedRole === 'Shipper' && (<>
                <Dropdown.Item as={Link} to={`/logistics-objects/create/piece`}>Piece</Dropdown.Item>

              </>)}
              {props?.selectedRole === 'Forwarder' && (<>
                <Dropdown.Item as={Link} to={`/logistics-objects/create/shipment`}>Shipment</Dropdown.Item>
                <Dropdown.Item as={Link} to={`/logistics-objects/create/waybill`}>Waybill</Dropdown.Item>
              </>)}
              {props?.selectedRole === 'Carrier' && (<>
                <Dropdown.Item as={Link} to={`/logistics-objects/create/unit-composition`}>Unit Composition</Dropdown.Item>
                <Dropdown.Item as={Link} to={`/logistics-objects/create/loading`}>Loading</Dropdown.Item>
                <Dropdown.Item as={Link} to={`/logistics-objects/create/transport-movement`}>Transport Movement</Dropdown.Item>
              </>)}
            </Dropdown>
          </div>

        </div>

        <div className='relative overflow-x-auto'>
          <table className='w-full text-sm text-left text-gray-500'>
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-6 py-3">
                  ID
                </th>
                <th className="px-6 py-3">
                  Type
                </th>
                <th className="px-6 py-3">
                  Data Owner
                </th>
                <th className="px-6 py-3">
                  Last Update
                </th>
              </tr>
            </thead>
            <tbody>
              {logisticsObjects?.map((logisticsObject, i) => {
                return (
                  <tr className='bg-white border-b dark:bg-gray-800 dark:border-gray-700'>
                    <td className='px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white'>
                      <Link className="underline font-semibold" to={`/logistics-objects/details?logisticsObjectURI=${logisticsObject["@id"]}`}>{logisticsObject["@id"].split("/logistics-objects/").pop()}</Link>
                    </td>
                    <td className="px-6 py-4">
                      {toTitleCase(logisticsObject["@type"].split('#').pop())}
                    </td>
                    <td className={`px-6 py-4 text-${getColorByLogisticsObjectURI(logisticsObject["@id"])}-700`}>
                      {getDataOwnerNameByLogisticsObjectURI(logisticsObject['@id'])}
                    </td>
                    <td className="px-6 py-4">
                      {renderTimeSinceLastUpdate(logisticsObject['Last-Modified'])}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

      </div>
    </>
  );
}
