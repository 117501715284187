import 'reactflow/dist/style.css';
import { useEffect, useState } from 'react';
import React, { useCallback } from 'react';
import ReactFlow, {
  addEdge,  
  Controls,  
  useNodesState,
  useEdgesState,
} from 'reactflow';
import CustomNode from './CustomNode';
import 'reactflow/dist/style.css';
import LogisticsEventNode from './LogisticsEventNode';
import Scenario1 from './scenarios/scenario1/scenario1';
import Scenario2 from './scenarios/scenario2/scenario2';
import { useParams } from 'react-router-dom';

const nodeTypes = {
  custom: CustomNode,
  logisticsEvent: LogisticsEventNode
};


function ScenarioViewer() {

  let { scenarioId } = useParams();

  const [scenario, setScenario] = useState(null);

  const [step, setStep] = useState(1);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [selectedDataOwner, setSelectedDataOwner] = useState(null);





  const onConnect = useCallback((connection) => {
    setEdges((eds) => addEdge(connection, eds));
  }, []);


  function nextStep() {
    if (step === Object.keys(scenario['steps']).length) return;
    setStep(step + 1);
  }
  function prevStep() {
    if (step === 1) return;
    setStep(step - 1);
  }

  useEffect(() => {        
    if (scenarioId === 'scenario1') {      
      setScenario(Scenario1);
    } else if (scenarioId === 'scenario2') {      
      setScenario(Scenario2);
    }    
    var d;
    // if steps contains key step than return data else return data_step1
    
    if (scenario !== null && scenario !== undefined && scenario.hasOwnProperty('steps')) {      
      let steps = scenario['steps'];
      d = steps[step]['data'];

      setNodes(d['nodes']);
      setEdges(d['edges']);      
    }

  }, [scenario,step]);

  if (scenario === null) {
    return (<div>Loading...</div>);
  }



  return (
    <div className={"relative"} style={{ height: '100vh' }}>

      <div className={'absolute top-6 left-6 z-10 space-y-5'} style={{ width: "400px" }}>
        <div className={'bg-white py-4 px-4 border-2 border-gray-200 shadow-md rounded-lg'}>
          <div className={'flex justify-between'}>
            <div className={'flex-1 w-64 text-lg font-bold pb-2'}>{scenario['name']}</div>
            <div className={'flex-initial w-10'}>
              {step > 1 ?
                <button className={"bg-blue-500 hover:bg-blue-700 text-sm text-white font-semibold hover:text-white py-1 px-3 border border-blue-500 hover:border-transparent rounded select-none"} onClick={prevStep}>
                  «
                </button> : null}
            </div>
            <div className={'flex-initial w-7'}>
              {step < Object.keys(scenario['steps']).length ?
                <button className={"bg-blue-500 hover:bg-blue-700 text-sm text-white font-semibold hover:text-white py-1 px-3 border border-blue-500 hover:border-transparent rounded select-none"} onClick={nextStep}>
                  »
                </button> : null}
            </div>
          </div>

          <span className={'text-l text-gray-700'}>
            <ol className={'list-decimal list-inside space-y-1'}>
              {Object.keys(scenario['steps']).map((s, i) => {
                return (<li key={i} className={step >= s ? 'text-blue-900 font-bold' : 'text-gray-400'}>
                  {scenario['steps'][s]['title']}
                </li>);
              })}
            </ol>
          </span>
        </div>

        <div className={'bg-white py-1 px-4 border-2 border-gray-200 shadow-md rounded-lg'}>

          <div className={'flex flex-auto space-x-3 text-xs'}>
            <button className={`${selectedDataOwner == "LH Cargo" ? 'border-blue-950 bg-blue-600 text-white' : 'border-blue-600 hover:border-blue-950 hover:bg-blue-600 hover:text-white'} border-2 rounded-md my-3 ps-2 pe-2`} onClick={
              () => {
                setSelectedDataOwner(selectedDataOwner == 'LH Cargo' ? null : 'LH Cargo');
              }
            }>LH Cargo</button>
            <button className='border-2 rounded-md border-red-600 hover:border-red-950 hover:bg-red-600 hover:text-white my-3 ps-2 pe-2'>DB Schenker</button>
            <button className='border-2 rounded-md border-gray-600 hover:border-gray-950 hover:bg-gray-600 hover:text-white my-3 ps-2 pe-2'>Apple</button>
            <button className='border-2 rounded-md border-orange-600 hover:border-orange-950 hover:bg-orange-600 hover:text-white my-3 ps-2 pe-2'>Jettainer</button>
          </div>


          <div>

          </div>
        </div>
      </div>

      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        panOnDrag={true}
        nodesDraggable={true}

        defaultViewport={{ x: window.innerWidth / 4, y: window.innerHeight / 7, zoom: window.innerWidth / window.innerHeight / 3 }}
        attributionPosition="top-right"
        className={"bg-white"}
      >
        <Controls />
      </ReactFlow>
      );
    </div>
  );
}

export default ScenarioViewer;
