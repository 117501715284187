import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import PlainJSONLD from "./LinkedDataViewer/PlainJSONLD";
import JSONLDTable from "./LinkedDataViewer/JSONLDTable";
import GraphViewer from "./LinkedDataViewer/GraphViewer";
import LogisticsEventTable from "./LogisticsEventTable";
import * as jsonld from "jsonld";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isAdvancedModeEnabled } from "../utils";



export default function LogisticsObjectDetails() {


  const [searchParams] = useSearchParams();
  const logisticsObjectURI = searchParams.get("logisticsObjectURI");

  const [logisticsObject, setLogisticsObject] = useState(null);
  const [logisticsEvents, setLogisticsEvents] = useState(null);
  const [latestRevision, setLatestRevision] = useState(null);
  const [lastModified, setLastModified] = useState(null);
  const [nQuads, setNQuads] = useState(null);
  const [edges, setEdges] = useState([]);
  const [nodes, setNodes] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedTab2, setSelectedTab2] = useState(0);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    setLogisticsObject(null);
    setLogisticsEvents(null);
    if (searchParams.has("logisticsObjectURI")) {
      setLoading(true);
      fetch(`https://index.one-record.de/logistics-objects?logisticsObjectURI=${logisticsObjectURI}`)
        .then((response) => {
          if (response.status === 404) {
            setNotFound(true);
          }
          return response.json()
        })
        .then((data) => {
          setLogisticsObject(data);
          setLoading(false);
        }).catch(error => {
          console.log(error);
        });

      fetch(`https://index.one-record.de/logistics-events?logisticsObjectURI=${logisticsObjectURI}`)
        .then((response) => { return response.json() })
        .then((data) => {
          //check if data has key error
          if (!data.hasOwnProperty("error")) {
            setLogisticsEvents(data);
          }
          setLoading(false);
        }).catch(error => {
          console.log(error);
        });
    }

  }, [searchParams]);

  useEffect(() => {
    if (logisticsObject !== null) {
      if ("Last-Modified" in logisticsObject) {
        setLastModified(logisticsObject["Last-Modified"]);
      }
      if ("Latest-Revision" in logisticsObject) {
        setLatestRevision(logisticsObject["Latest-Revision"]);
      }
      delete logisticsObject["Last-Modified"];
      delete logisticsObject["Latest-Revision"];
    }
    const transformData = async () => {
      const nquads = await jsonld.toRDF(logisticsObject, {
        format: "application/n-quads",
      });
      setNQuads(nquads);
      const statements = nquads.split("\n");

      let nodes = [];
      let edges = [];

      statements.forEach((statement) => {
        if (statement.includes("<")) {
          var result = statement.match(/<(.*?)>/g).map(function (val) {
            return val.replace("<", "").replace(">", "");
          });
          if (result.length === 3) {
            if (result[0].includes("/logistics-objects/")) {
              nodes.push({
                id: result[0],
                type: "custom",
                sourcePosition: "right",
                position: { x: 0, y: 0 },
                data: {
                  label: result[0].split("/logistics-objects/").pop(),
                },
              });
            }

            if (
              result[0].includes("/logistics-objects/") &&
              result[2].includes("/logistics-objects/")
            ) {
              nodes.push({
                id: result[2],
                type: "output",
                targetPosition: "left",
                position: { x: 350, y: (edges.length - 0) * 90 },
                data: {
                  label: result[2].split("/logistics-objects/").pop(),
                },
              });

              edges.push({
                id: edges.length,
                source: result[0],
                target: result[2],
                label: result[1].split("#").pop(),
              });
            }
          }
        }
      });
      setNodes(nodes);
      setEdges(edges);
    };
    transformData().catch(console.error);
  }, [logisticsObject]);

  if (isLoading) return <p>Loading...</p>;
  if (!logisticsObject) return <p>Data could not be loaded</p>;

  const renderLogisticsEventsTable = () => {    
    if (logisticsEvents === undefined || logisticsEvents === null || logisticsEvents.length === 0) {
      return <p>No LogisticsEvents found</p>;
    }
    let transformedLogisticsEvents;;
    if (logisticsEvents !== null && logisticsEvents !== undefined) {
      if ("@graph" in logisticsEvents) {
        transformedLogisticsEvents = logisticsEvents["@graph"];
      } else if ("@type" in logisticsEvents && logisticsEvents["@type"].endsWith("#LogisticsEvent")) {
        transformedLogisticsEvents = [logisticsEvents];
      }
    }
    return (
      <div className="pt-5 pb-5">
        {isAdvancedModeEnabled() && (
          <div className="mb-4 border-b border-gray-200 ">
            <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
              <li className="mr-2">
                <button className={`inline-block p-4 border-b-2 rounded-t-lg ${selectedTab2 !== 0 ? "border-transparent hover:text-gray-600 hover:border-gray-300 " : ""}`} onClick={() => { setSelectedTab2(0) }}>
                  <FontAwesomeIcon icon={["fas", "table"]} className="pr-3" />Table</button>
              </li>
              <li className="mr-2">
                <button className={`inline-block p-4 border-b-2 rounded-t-lg ${selectedTab2 !== 1 ? "border-transparent hover:text-gray-600 hover:border-gray-300 " : ""}`} onClick={() => { setSelectedTab2(1) }}>
                  <FontAwesomeIcon icon={["fas", "code"]} className="pr-3" />JSON-LD</button>
              </li>
            </ul>
          </div>)}
        {(selectedTab2 === 0 && (<LogisticsEventTable data={transformedLogisticsEvents} />))}
        {(selectedTab2 === 1 && (<PlainJSONLD data={logisticsEvents} />))}

      </div>
    );
  };

  return (
    <div className="p-4 lg:ml-72 mr-5">
      <div className="flex items-baseline space-x-2 border-b-2 pb-2">
        <div className="text-blue-950 font-bold">LogisticsObject</div>
        <svg className="w-3 h-3 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 10 16">
          <path d="M3.414 1A2 2 0 0 0 0 2.414v11.172A2 2 0 0 0 3.414 15L9 9.414a2 2 0 0 0 0-2.828L3.414 1Z" />
        </svg><div> {logisticsObjectURI}
        </div>
      </div>
      <div className="flex space-x-5 text-sm">
        {latestRevision ? (
          <div className="pt-2">
            <div className="text-sm text-gray-700 flex space-x-2">
              <svg className="w-4 h-4 text-gray-800 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 20">
                <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.98 2.98 0 0 0 .13 5H5Z" />
                <path d="M14.066 0H7v5a2 2 0 0 1-2 2H0v11a1.97 1.97 0 0 0 1.934 2h12.132A1.97 1.97 0 0 0 16 18V2a1.97 1.97 0 0 0-1.934-2Z" />
              </svg>
              <div className="">
                Latest Revision: {latestRevision}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}



        {lastModified ? (
          <div className="pt-2">
            <div className="text-sm text-gray-700  flex space-x-2">
              <svg className="w-4 h-4 text-gray-800 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10 0a10 10 0 1 0 10 10A10.011 10.011 0 0 0 10 0Zm3.982 13.982a1 1 0 0 1-1.414 0l-3.274-3.274A1.012 1.012 0 0 1 9 10V6a1 1 0 0 1 2 0v3.586l2.982 2.982a1 1 0 0 1 0 1.414Z" />
              </svg>
              <div className="">
                Last-Modified: {new Date(lastModified).toISOString().split('.')[0] + "Z"}</div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      {notFound === false ? (

        <div className="pt-10 pb-5">
          {isAdvancedModeEnabled() && (
            <div className="mb-4 border-b border-gray-200 ">
              <ul className="flex flex-wrap -mb-px text-sm font-medium text-center" id="myTab" data-tabs-toggle="#myTabContent" role="tablist">
                <li className="mr-2">
                  <button className={`inline-block p-4 border-b-2 rounded-t-lg ${selectedTab !== 0 ? "border-transparent hover:text-gray-600 hover:border-gray-300 " : ""}`} onClick={() => { setSelectedTab(0) }}>
                    <FontAwesomeIcon icon={["fas", "table"]} className="pr-3" />Table</button>
                </li>
                <li className="mr-2">
                  <button className={`inline-block p-4 border-b-2 rounded-t-lg ${selectedTab !== 1 ? "border-transparent hover:text-gray-600 hover:border-gray-300 " : ""}`} onClick={() => { setSelectedTab(1) }}>
                    <FontAwesomeIcon icon={["fas", "code"]} className="pr-3" />JSON-LD</button>
                </li>
                <li className="mr-2">
                  <button className={`inline-block p-4 border-b-2 rounded-t-lg ${selectedTab !== 2 ? "border-transparent hover:text-gray-600 hover:border-gray-300 " : ""}`} onClick={() => { setSelectedTab(2) }}>
                    <FontAwesomeIcon icon={["fas", "diagram-project"]} className="pr-3" />Graph</button>
                </li>
                <li>
                  <button className={`inline-block p-4 border-b-2 rounded-t-lg ${selectedTab !== 3 ? "border-transparent hover:text-gray-600 hover:border-gray-300 " : ""}`} onClick={() => { setSelectedTab(3) }}>
                    <FontAwesomeIcon icon={["fas", "bars"]} className="pr-3" />RDF (N-Quads)</button>
                </li>
              </ul>
            </div>
          )}
          {(selectedTab === 0 && (<JSONLDTable data={logisticsObject} />))}
          {(selectedTab === 1 && (<PlainJSONLD data={logisticsObject} />))}
          {(selectedTab === 2 && (<GraphViewer nodes={nodes} edges={edges} />))}
          {(selectedTab === 3 && (
            <textarea className="resize-none text-xs block p-2.5 w-5/6 text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500      "
              value={nQuads} readOnly rows={nQuads.split("\n").length} />
          ))}
        </div>
      ) :
        <div>Not Found</div>
      }
      {(logisticsEvents !== null && logisticsEvents !== undefined) && (
        <div className="pt-3">
          <div md={2} />
          <div md={8}>
            <div className="flex items-baseline space-x-2 border-b-2 pb-2">
              <div className="text-blue-950 font-bold">LogisticsEvents</div>
              <svg className="w-3 h-3 text-gray-800 " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 10 16">
                <path d="M3.414 1A2 2 0 0 0 0 2.414v11.172A2 2 0 0 0 3.414 15L9 9.414a2 2 0 0 0 0-2.828L3.414 1Z" />
              </svg><div> {logisticsObjectURI}/logistics-events
              </div>
            </div>

            <div className="pt-4">{renderLogisticsEventsTable()}</div>
          </div>
        </div>
      )}
    </div>
  );
}
