import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

function LogisticsEventNode({ data }) {
  return (
    <div className={`px-4 py-2 shadow-md rounded-md bg-white border-2 border-${data.color}-600`} style={{width: '195px'}}>       
      <Handle id="logisticsObject" type="target" position={Position.Top} />
      <div className="flex">        
        <div className="ml-2">
          <div className="text-lg">{data.logisticsObjectId}</div>
          <div className="text-gray-500 font-bold">{data.logisticsObjectType}</div>
        </div>
      </div>
    </div>
  );
}

export default memo(LogisticsEventNode);
