import React, { useState } from "react";
import logo from "../assets/logo.png"
import { Link, NavLink } from "react-router-dom";
import { disableAdvancedMode, enableAdvancedMode, getColorByRole, isAdvancedModeEnabled } from "../utils";
import { Dropdown } from "flowbite-react";

export default function Navbar(props) {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const advancedModeOn = isAdvancedModeEnabled();


    const handleToggle = () => {
        setNavbarOpen((current) => !current);
    };


    return (
        <>
            <nav className="relative flex flex-wrap items-center justify-between px-2 py-1 bg-white mb-3 border-b-blue-700 border-b z-50" style={{ "width": "100vw" }}>
                <div className="w-full px-4 mx-auto flex flex-wrap items-center justify-between">
                    <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
                        <Link to={`/`} className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-blue">
                            <img src={logo} className="h-6 mr-3 float-left" alt="ONE Record Demo logo" />
                            <span className="inline-block">ONE Record Demo</span>
                        </Link>
                        <button
                            className="text-blue cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
                            type="button"
                            onClick={handleToggle}
                        >
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                            </svg>
                        </button>
                    </div>
                    <div className="flex flex-row w-full lg:w-6">
                        <div className="md:ml-2 md:px-3 py-2 flex items-center text-sm leading-snug text-blue z-50">
                            {typeof (props?.selectedRole) === "string" ? (
                                <>
                                    <span className="font-bold uppercase pr-2">Role:</span>
                                    <span className={`text-${getColorByRole(props?.selectedRole)}-700`}>{props?.selectedRole}</span>
                                    <Link to={`/`}><svg className="w-4 h-4 ps-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 18">
                                        <path d="M12.687 14.408a3.01 3.01 0 0 1-1.533.821l-3.566.713a3 3 0 0 1-3.53-3.53l.713-3.566a3.01 3.01 0 0 1 .821-1.533L10.905 2H2.167A2.169 2.169 0 0 0 0 4.167v11.666A2.169 2.169 0 0 0 2.167 18h11.666A2.169 2.169 0 0 0 16 15.833V11.1l-3.313 3.308Zm5.53-9.065.546-.546a2.518 2.518 0 0 0 0-3.56 2.576 2.576 0 0 0-3.559 0l-.547.547 3.56 3.56Z" />
                                        <path d="M13.243 3.2 7.359 9.081a.5.5 0 0 0-.136.256L6.51 12.9a.5.5 0 0 0 .59.59l3.566-.713a.5.5 0 0 0 .255-.136L16.8 6.757 13.243 3.2Z" />
                                    </svg></Link>
                                </>) : ""}
                        </div>
                        {/* <div className="z-50 w-64 pl-10">
                            <Dropdown color={'gray'} arrowIcon={false} label="Create data" size={"xs"} className="break-keep">
                                {props?.selectedRole === 'Shipper' && (<>
                                    <Dropdown.Item as={Link} to={`/logistics-objects/create/piece`}>Piece</Dropdown.Item>

                                </>)}
                                {props?.selectedRole === 'Forwarder' && (<>
                                    <Dropdown.Item as={Link} to={`/logistics-objects/create/shipment`}>Shipment</Dropdown.Item>
                                    <Dropdown.Item as={Link} to={`/logistics-objects/create/waybill`}>Waybill</Dropdown.Item>
                                </>)}
                                {props?.selectedRole === 'Carrier' && (<>
                                    <Dropdown.Item as={Link} to={`/logistics-objects/create/unit-composition`}>Unit Composition</Dropdown.Item>
                                    <Dropdown.Item as={Link} to={`/logistics-objects/create/loading`}>Loading</Dropdown.Item>
                                    <Dropdown.Item as={Link} to={`/logistics-objects/create/transport-movement`}>Transport Movement</Dropdown.Item>
                                </>)}
                            </Dropdown>
                        </div> */}
                    </div>
                    <div
                        className={
                            "lg:flex flex-grow items-center" +
                            (navbarOpen ? " flex" : " hidden")
                        }
                    >
                        <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
                            <li className="nav-item pt-2">
                                <NavLink to={`/`} className="px-3 py-2 flex items-center text-xs uppercase leading-snug text-blue hover:opacity-75">
                                    <span className="ml-2">Home</span>
                                </NavLink>
                            </li>
                            <li className="nav-item pt-2">
                                <NavLink to={`/demos`}
                                    className="px-3 py-2 flex items-center text-xs uppercase leading-snug text-blue hover:opacity-75"
                                >
                                    <span className="ml-2">Demos</span>
                                </NavLink>
                            </li>
                            {typeof (props?.selectedRole) === "string" ? (
                                <li className="nav-item pt-2">
                                    <NavLink to={`/logistics-objects`}
                                        className="px-3 py-2 flex items-center text-xs uppercase leading-snug text-blue hover:opacity-75"
                                    >
                                        <span className="ml-2">Logistics Objects</span>
                                    </NavLink>
                                </li>) : ""}
                            <li className="nav-item pt-2">
                                <NavLink to={`/live`} className="px-3 py-2 flex items-center text-xs uppercase leading-snug text-blue hover:opacity-75">
                                    <span className="ml-2">Live</span>
                                </NavLink>
                            </li>
                            <li className="nav-item pt-3">
                                <div className="px-2">
                                    <label className="relative inline-flex items-center mb-5 cursor-pointer">
                                        <input type="checkbox" value="" className="sr-only peer" checked={advancedModeOn} onChange={
                                            (e) => {
                                                if (e.target.checked) {
                                                    enableAdvancedMode();
                                                } else {
                                                    disableAdvancedMode()
                                                }
                                                window.location.reload();
                                            }
                                        } />
                                        <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                                        <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Advanced mode</span>
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav >
        </>
    );
}