import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Value from "./Value";
import Dimensions from "./Dimensions";
import DateTime from "./DateTime";
import Boolean from "./Boolean";
import ContactDetails from "./ContactDetails";
import HandlingInstructions from "./HandlingInstructions";
import Party from "./Party";
import OtherIdentifier from "./OtherIdentifier";
import Address from "./Address";
import VolumetricWeight from "./VolumetricWeight";
import RegulatedEntity from "./RegulatedEntity";
import ActivitySequence from "./ActivitySequence";
import MovementTime from "./MovementTime";
import Integer from "./Integer";
import Double from "./Double";
import Float from "./Float";

export default function Node({ data }) {
  if (typeof data === "string") {
    if (data.startsWith("http://") || data.startsWith("https://")) {
      if (data.includes("/logistics-objects/")) {
        //const logisticsObjectId = data.split("/logistics-objects/").pop();
        if (data.includes("/logistics-events/")) {
          return (
          <a href={data} target="_blank" rel="noreferrer">
            {data} <FontAwesomeIcon icon={["fas", "external-link"]} />
            </a>
          );
        }
        return (
          <Link to={`/logistics-objects/details?logisticsObjectURI=${data}`}>{data}</Link>
        );
      }
      return (
        <a target="_blank" rel="noreferrer" href={data}>
          {data} <FontAwesomeIcon icon={["fas", "external-link"]} />
        </a>
      );
    }

    return data;
  }
  if (data.constructor === Object) {
    if ("@type" in data && typeof data["@type"] === "string") {
      let dataClass = data["@type"].split("#").pop();
      switch (dataClass) {
        case "dateTime":
          return <DateTime data={data} />;          
        case "boolean":
          return <Boolean data={data} />;          
        case "float":
          return <Float data={data} />;          
        case "double":
          return <Double data={data} />;          
        case "integer":
          return <Integer data={data} />;        
      }
      dataClass = data["@type"].split("xsd:").pop();
      switch (dataClass) {
        case "dateTime":
          return <DateTime data={data} />;          
        case "boolean":
          return <Boolean data={data} />;          
        case "float":
          return <Float data={data} />;          
        case "double":
          return <Double data={data} />;          
        case "integer":
          return <Integer data={data} />                  
      }
    }

    if (
      "@type" in data &&
      typeof data["@type"] === "string" &&
      data["@type"].endsWith("XMLSchema#boolean")
    ) {
      return <Boolean data={data} />;
    }

    if ("@id" in data) {
      if (data["@id"].startsWith("neone:") && "@type" in data) {
        if (
          "@type" in data &&
          typeof data["@type"] === "string" &&
          data["@type"].startsWith("https://onerecord.iata.org/ns/cargo")
        ) {
          let dataClass = data["@type"].split("#").pop();
          switch (dataClass) {
            case "ContactDetail":
              return <ContactDetails data={data} />;              
            case "Dimensions":
              return <Dimensions data={data} />;
            case "Value":
              return <Value data={data} />;
            case "HandlingInstructions":
              return <HandlingInstructions data={data} />;
            case "Party":
              return <Party data={data} />;
            case "OtherIdentifier":
              return <OtherIdentifier data={data} />;
            case "Address":
              return <Address data={data} />;
            case "VolumetricWeight":
              return <VolumetricWeight data={data} />;
            case "RegulatedEntity":
              return <RegulatedEntity data={data} />;
            case "ActivitySequence":
              return <ActivitySequence data={data} />;
            case "MovementTime":
              return <MovementTime data={data} />;
            default:
              return <Node data={data["@id"]} />;
          }
        }
      }
      if (
        data["@id"].startsWith("http://") ||
        data["@id"].startsWith("https://")
      ) {
        if (data["@id"].includes("/logistics-objects/")) {
          return <Node data={data["@id"]} />;
        }
      }
      return <Node data={data["@id"]} />;
    }
  }
  if (Array.isArray(data) && data.length > 0) {
    return (
      <ul>
        {data.map((item, index) => (
          <li key={index}>
            <Node data={item} />
          </li>
        ))}
      </ul>
    );
  }

}
