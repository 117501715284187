import React from "react";
import ReactFlow from "reactflow";

import "reactflow/dist/style.css";
import 'reactflow/dist/base.css';
import LogisticsObjectDetailsNode from "../ReactFlow/LogisticsObjectDetailsNode"


const nodeTypes = {
  custom: LogisticsObjectDetailsNode,
};


export default function GraphViewer(props) {
  return (
    <div style={{ width: "70vw", height: "40vh" }}>
      <ReactFlow
        nodes={props.nodes}
        edges={props.edges}
        zoomOnScroll={false}
        zoomOnDoubleClick={false}
        zoomOnPinch={false} 
        nodeTypes={nodeTypes}
        
      />
    </div>
  );
}
