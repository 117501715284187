import './CustomNode.css';
import { memo } from 'react';
import { Handle, Position } from 'reactflow';

const LogisticsObjectDetailsNode = ({ data, isConnectable, sourcePosition = Position.Bottom }) => (
  <div className='border border-blue-900 rounded p-3'>
    {data?.label}
    <Handle type="source" position={sourcePosition} isConnectable={isConnectable} />
  </div>
);

LogisticsObjectDetailsNode.displayName = 'LogisticsObjectDetailsNode';

export default memo(LogisticsObjectDetailsNode);