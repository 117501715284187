// https://onerecord.iata.org/ns/cargo#RegulatedEntity

import DateTime from "./DateTime";
import DateValue from "./DateValue";
import LogisticsObject from "./LogisticsObject";

export default function RegulatedEntity({ key, data }) {
  const template = [];

  if ("https://onerecord.iata.org/ns/cargo#category" in data) {
    template.push(
      <span style={{ paddingRight: "5px" }}>Category: {data["https://onerecord.iata.org/ns/cargo#category"]}
        <br />
      </span> 
    );
  }

  if ("https://onerecord.iata.org/ns/cargo#organization" in data) {
    template.push(
      <LogisticsObject
        data={data["https://onerecord.iata.org/ns/cargo#organization"]}
      />
    );
  }

  if ("https://onerecord.iata.org/ns/cargo#owningOrganization" in data) {
    template.push(
      <LogisticsObject
        data={data["https://onerecord.iata.org/ns/cargo#owningOrganization"]}
      />
    );
  }

  if(template.length>0)
  {
    template.push(<br/>);
  }
 

  if ("https://onerecord.iata.org/ns/cargo#expiryDate" in data) {
    template.push(
      <span>        
        Expiry date:{" "}
        <DateValue
          data={data["https://onerecord.iata.org/ns/cargo#expiryDate"]}
        />
      </span>
    );
  }

  if ("https://onerecord.iata.org/ns/cargo#regulatedEntityExpiryDate" in data) {
    template.push(
      <span>        
        Expiry date:{" "}
        <DateTime
          data={
            data[
              "https://onerecord.iata.org/ns/cargo#regulatedEntityExpiryDate"
            ]
          }
        />
      </span>
    );
  }

  return <>{template}</>;
}
