import { useEffect, useState } from "react";
import { Button, Checkbox, Label, Radio, TextInput, Textarea } from 'flowbite-react';
import { getDataOwnerNameByLogisticsObjectURI } from "../../utils";


export default function WaybillForm(props) {

    const type = "https://onerecord.iata.org/ns/cargo#Waybill";

    const [id, setId] = useState("");
    const [waybillType, setWaybillType] = useState("Master");
    const [waybillPrefix, setWaybillPrefix] = useState("");
    const [waybillNumber, setWaybillNumber] = useState("")
    const [departureLocation, setDepartureLocation] = useState(null);
    const [arrivalLocation, setArrivalLocation] = useState(null);
    const [shipment, setShipment] = useState(null);


    const [idColor, setIdColor] = useState(null);
    const [waybillTypeColor, setWaybillTypeColor] = useState(null);
    const [waybillPrefixColor, setWaybillPrefixColor] = useState(null);
    const [waybillNumberColor, setWaybillNumberColor] = useState(null)
    const [departureLocationColor, setDepartureLocationColor] = useState(null);
    const [arrivalLocationColor, setArrivalLocationColor] = useState(null);
    const [shipmentColor, setShipmentColor] = useState(null);

    const [availableShipments, setAvailableShipments] = useState(null);
    const [availableLocations, setAvailableLocations] = useState(null);


    const [error, setError] = useState(null);

    useEffect(() => {
        let logisticsObject = {
            "@type": type,            
            "@id": id,
            "waybillType": waybillType,        
            "waybillPrefix": waybillPrefix,
            "waybillNumber": waybillNumber,
            "departureLocation": departureLocation,
            "arrivalLocation": arrivalLocation,
            "shipment": shipment
        };
        props.setLogisticsObject(logisticsObject);
        props.setJsonLD(logisticsObject);
    }, [id, waybillType, waybillPrefix, waybillNumber, departureLocation, arrivalLocation, shipment]);

    useEffect(() => {
        if (availableShipments === null) {
            fetch("https://index.one-record.de/logistics-objects?logisticsObjectTypes=https%3A%2F%2Fonerecord.iata.org%2Fns%2Fcargo%23Shipment", {
                method: "GET"
            })
                .then(response => response.json())
                .then(data => {
                    setAvailableShipments(data);
                }
                );
        }

    }, [availableShipments]);

    useEffect(() => {
        if (availableLocations === null) {
            fetch("https://index.one-record.de/logistics-objects?logisticsObjectTypes=https%3A%2F%2Fonerecord.iata.org%2Fns%2Fcargo%23Location", {
                method: "GET"
            })
                .then(response => response.json())
                .then(data => {
                    setAvailableLocations(data);
                }
                );
        }

    }, [availableLocations]);





    return (
        <>
            <form className="flex max-w-md flex-col gap-4">
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="id"
                            value="ID*"
                        />
                    </div>
                    <TextInput
                        id="id"
                        helperText={<>This MUST be a unique id, allowed characters: a-z, 0-9, -</>}
                        required
                        shadow
                        type="text"
                        value={id}
                        color={idColor}
                        onChange={(e) => {
                            e.preventDefault();
                            //replace uppercase with lowercase
                            let sanitized = e.target.value.toLowerCase();
                            // replace whitespace with dash     
                            sanitized = sanitized.replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
                            setId(sanitized);
                        }}
                        onBlur={(e) => {
                            e.preventDefault();
                            // if multiple dashes in a row, replace with single dash
                            let sanitized = e.target.value.replace(/-+/g, '-');
                            //replace last dash with whitespace                            
                            sanitized = sanitized.replace(/-$/, ' ');
                            setId(sanitized);
                        }}
                    />
                </div>

                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="waybillPrefix"
                            value="Waybill prefix*"
                        />
                    </div>
                    <TextInput
                        id="waybillPrefix"
                        helperText={<>This MUST be a 3-digit number</>}
                        shadow
                        required
                        color={waybillPrefixColor}
                        type="number"                        
                        pattern={"[0-9]+?"}
                        value={waybillPrefix}
                        onChange={(e) => {
                            let sanitized = e.target.value.substring(0, 3);
                            setWaybillPrefix(sanitized);
                        }}  
                        onBlur={(e) => {
                            // if more than three numbers, only keep the first three
                            let sanitized = e.target.value.substring(0, 3);
                            setWaybillPrefix(sanitized);
                        }}
                    />
                </div> 

                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="waybillNumber"
                            value="Waybill number*"
                        />
                    </div>
                    <TextInput
                        id="waybillNumber"
                        helperText={<>This MUST be a 8-digit number</>}
                        shadow
                        required
                        color={waybillNumberColor}
                        type="number"                        
                        pattern={"[0-9]+?"}
                        value={waybillNumber}
                        onChange={(e) => {
                            let sanitized = e.target.value.substring(0, 8);
                            setWaybillNumber(sanitized);
                        }}      
                        onBlur={(e) => {
                            let sanitized = e.target.value.substring(0, 8);
                            setWaybillNumber(sanitized);
                        }}                      
                    />
                </div> 

                {/* Shipment */}
                <div>
                    <fieldset className="flex max-w-md flex-col gap-4" id="shipment" onChange={(e) => { setShipment(e.target.value); }}>
                        <legend className="mb-4">
                            Shipment*
                        </legend>
                        {availableShipments !== null && availableShipments.length === 0 && <div className="text-red-600 text-sm">No Shipments found.</div>}

                        {availableShipments?.map((availableShipment) => (
                            <div className="flex items-center gap-2" key={availableShipment["@id"]}>
                                <Radio
                                    id={availableShipment['@id']}
                                    value={availableShipment['@id']}
                                    name="shipment"
                                    checked={availableShipment['@id'] === shipment}
                                />
                                <Label htmlFor={availableShipment['@id']} className="font-normal">
                                    {availableShipment['@id'].split("/logistics-objects/").pop()} @ {getDataOwnerNameByLogisticsObjectURI(availableShipment['@id'])}
                                </Label>

                            </div>
                        ))}
                    </fieldset>
                </div>
                <div>{shipmentColor !== null && <div className="text-red-600 text-sm">Select one shipment.</div>}</div>

                {/* Departure Location */}
                <div className="pt-2">
                    <fieldset className="flex max-w-md flex-col gap-4" id="departureLocation" onChange={(e) => { setDepartureLocation(e.target.value); }}>
                        <legend className="mb-4">
                            Departure location*
                        </legend>
                        {availableLocations !== null && availableLocations.length === 0 && <div className="text-red-600 text-sm">No locations found.</div>}

                        {availableLocations?.map((availableLocation) => (
                            <div className="flex items-center gap-2" key={availableLocation["@id"]}>
                                <Radio
                                    id={`d-${availableLocation['@id']}`}
                                    value={availableLocation['@id']}
                                    name="departureLocation"
                                    checked={availableLocation['@id'] === departureLocation}
                                />
                                <Label htmlFor={`d-${availableLocation['@id']}`} className="font-normal">
                                    {availableLocation['@id'].split("/logistics-objects/").pop()}
                                </Label>

                            </div>
                        ))}
                    </fieldset>
                </div>
                <div>{departureLocationColor !== null && <div className="text-red-600 text-sm">Select one departure location.</div>}</div>

                 {/* Arrival Location */}
                 <div>
                    <fieldset className="flex max-w-md flex-col gap-4" id="arrivalLocation" onChange={(e) => { setArrivalLocation(e.target.value); }}>
                        <legend className="mb-4">
                            Arrival location*
                        </legend>
                        {availableLocations !== null && availableLocations.length === 0 && <div className="text-red-600 text-sm">No locations found.</div>}

                        {availableLocations?.map((availableLocation) => (
                            <div className="flex items-center gap-2" key={availableLocation["@id"]}>
                                <Radio
                                    id={`a-${availableLocation['@id']}`}
                                    value={availableLocation['@id']}
                                    name="arrivalLocation"
                                    checked={availableLocation['@id'] === arrivalLocation}
                                />
                                <Label htmlFor={`a-${availableLocation['@id']}`} className="font-normal">
                                    {availableLocation['@id'].split("/logistics-objects/").pop()}
                                </Label>

                            </div>
                        ))}
                    </fieldset>
                </div>
                <div>{arrivalLocationColor !== null && <div className="text-red-600 text-sm">Select one arrival location.</div>}</div>




              



                {error && <div className="text-red-600 text-sm">{error}</div>}

                <button disabled={availableShipments !== null && availableShipments.length === 0} type={"submit"} onClick={(e) => {
                    e.preventDefault();
                    if (id === "" || waybillNumber === "" || waybillPrefix === "" ||  departureLocation === null || arrivalLocation === null || shipment === null || waybillType === "") {
                        if (id === "") {
                            setIdColor("failure");
                        }
                        if (waybillNumber === "") {
                            setWaybillNumberColor("failure");
                        }
                        if (waybillPrefix === "") {
                            setWaybillPrefixColor("failure");
                        }                        
                        if (departureLocation === null) {
                            setDepartureLocationColor("failure");
                        }
                        if (arrivalLocation === null) {
                            setArrivalLocationColor("failure");
                        }
                        if (shipment === null) {
                            setShipmentColor("failure");
                        }
                        if (waybillType === "") {
                            setWaybillTypeColor("failure");
                        }


                        setError("Please fill in all required");
                    } else {
                        setIdColor(null);
                        setWaybillTypeColor(null);
                        setWaybillPrefixColor(null);
                        setWaybillNumberColor(null);
                        setShipmentColor(null);
                        setDepartureLocationColor(null);
                        setArrivalLocationColor(null);


                        setError(null);

                        props.createLogisticsObject().then((result) => {
                            if (result !== "Error") {
                                setId("");
                                setWaybillType("");
                                setWaybillPrefix("");
                                setWaybillNumber("");
                                setDepartureLocation(null);
                                setArrivalLocation(null);
                                setShipment(null);
                            }
                        });
                    }
                }}
                    className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                    Create
                </button>
            </form >

        </>

    );
}
