import { useEffect, useState } from "react";
import { Button, Checkbox, Label, TextInput, Textarea } from 'flowbite-react';


export default function PieceForm(props) {

    const type = "https://onerecord.iata.org/ns/cargo#Piece";

    const [id, setId] = useState("");
    const [goodsDescription, setGoodsDescription] = useState("");
    const [grossWeight, setGrossWeight] = useState("");

    const [idColor, setIdColor] = useState(null);
    const [goodsDescriptionColor, setGoodsDescriptionColor] = useState(null);
    const [grossWeightColor, setGrossWeightColor] = useState(null);

    const [error, setError] = useState(null);

    useEffect(() => {
        let logisticsObject = {
            "@type": type,
            "@id": id,
            "goodsDescription": goodsDescription,
            "grossWeight": grossWeight,
        };
        props.setLogisticsObject(logisticsObject);
        props.setJsonLD(logisticsObject);
    }, [id, goodsDescription, grossWeight]);


    return (
        <>
            <form className="flex max-w-md flex-col gap-4">
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="id"
                            value="ID*"
                        />
                    </div>
                    <TextInput
                        id="id"                        
                        helperText={<>This MUST be a unique id, allowed characters: a-z, 0-9, -</>}
                        required
                        shadow
                        type="text"
                        value={id}
                        color={idColor}
                        onChange={(e) => {
                            e.preventDefault();
                            //replace uppercase with lowercase
                            let sanitized = e.target.value.toLowerCase();
                            // replace whitespace with dash     
                            sanitized = sanitized.replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
                            setId(sanitized);
                        }}
                        onBlur={(e) => {
                            e.preventDefault();                            
                            // if multiple dashes in a row, replace with single dash
                            let sanitized = e.target.value.replace(/-+/g, '-');
                            //replace last dash with whitespace                            
                            sanitized = sanitized.replace(/-$/, ' ');
                            setId(sanitized);
                        }}
                    />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="goodsDescription"
                            value="Goods description*"
                        />
                    </div>
                    <Textarea
                        id="goodsDescription"                                                
                        type="text"
                        shadow
                        rows={3}
                        required
                        value={goodsDescription}
                        color={goodsDescriptionColor}
                        onChange={(e) => {
                            setGoodsDescription(e.target.value);
                        }}
                    />
                </div>

                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="grossWeight"
                            value="Gross weight (in kilogram)*"
                        />
                    </div>
                    <TextInput
                        id="grossWeight"
                        helperText={<>This MUST be a decimal number</>}                        
                        shadow
                        required
                        color={grossWeightColor}
                        type="number"
                        step={0.01}
                        pattern={"[0-9]+([\.][0-9]+)?"}
                        value={grossWeight}
                        onChange={(e) => {
                            // only allow numbers and one decimal point
                            let sanitized = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
                            setGrossWeight(sanitized);
                        }}
                    />
                </div>
                {error && <div className="text-red-500">{error}</div>}

                <button type={"submit"} onClick={(e) => {
                    e.preventDefault();
                    if (id === "" || goodsDescription === "" || grossWeight === "") {  
                        if(id === "") {
                            setIdColor("failure");
                        }
                        if(goodsDescription === "") {
                            setGoodsDescriptionColor("failure");
                        }
                        if(grossWeight === "") {
                            setGrossWeightColor("failure");
                        }
                        setError("Please fill in all required");
                    } else {            
                        setIdColor(null);
                        setGoodsDescriptionColor(null);
                        setGrossWeightColor(null);            
                        setError(null);
                        props.createLogisticsObject().then((result) => {
                            if (result !== "Error") {
                                setId("");
                                setGoodsDescription("");
                                setGrossWeight("");
                            }
                        });
                    }
                }}
                    className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                    Create
                </button>
            </form>




        </>

    );
}
