import 'reactflow/dist/style.css';
import { useEffect, useState } from 'react';
import React from 'react';
import ReactFlow, {
    Controls,
    useNodesState,
    useEdgesState
} from 'reactflow';
import CustomNode from './CustomNode';
import 'reactflow/dist/style.css';
import LogisticsEventNode from './LogisticsEventNode';
import * as jsonld from 'jsonld';
import { getColorByLogisticsObjectURI } from './utils';

const nodeTypes = {
    custom: CustomNode,
    logisticsEvent: LogisticsEventNode
};

const logisticsObjectTypes = [
    "https://onerecord.iata.org/ns/cargo#Waybill",
    "https://onerecord.iata.org/ns/cargo#Shipment",
    "https://onerecord.iata.org/ns/cargo#Piece",
    "https://onerecord.iata.org/ns/cargo#UnitComposition",
    "https://onerecord.iata.org/ns/cargo#Uld",
    "https://onerecord.iata.org/ns/cargo#Loading",
    "https://onerecord.iata.org/ns/cargo#TransportMeans",
    "https://onerecord.iata.org/ns/cargo#TransportMovement",
]
const logisticsActivityTypes = [
    "https://onerecord.iata.org/ns/cargo#UnitComposition",
    "https://onerecord.iata.org/ns/cargo#Loading",
    "https://onerecord.iata.org/ns/cargo#TransportMovement",
]

const indexServerUrl = "https://index.one-record.de";

const initNodes = [
    {
        "id": "activities",
        "data": {
            "label": "Activities"
        },
        "position": {
            "x": 0,
            "y": 0
        },
        "className": "light",
        "style": {
            "backgroundColor": "white",
            "paddingLeft": "20px",
            "zIndex": -1,
            "width": 2000,
            "height": 250
        }
    },
    {
        "id": "physics",
        "data": {
            "label": "Physics  / Digital Twin"
        },
        "position": {
            "x": 0,
            "y": 300
        },
        "className": "light",
        "style": {
            "backgroundColor": "white",
            "paddingLeft": "20px",
            "zIndex": -1,
            "width": 2000,
            "height": 650
        }
    },
    {
        "id": "contractuals",
        "data": {
            "label": "Contractuals"
        },
        "position": {
            "x": 0,
            "y": 1000
        },
        "className": "light",
        "style": {
            "backgroundColor": "white",
            "paddingLeft": "20px",
            "zIndex": -1,
            "width": 2000,
            "height": 200
        }
    }
]


function LiveViewer() {

    const [data, setData] = useState([]);
    const [nodes, setNodes, onNodesChange] = useNodesState(initNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const xSpacing = 250;

    const [links, setLinks] = useState([]);

    const onNodeClick = (event, node) => console.log('click node', node);

    useEffect(() => {
        async function fetchData() {
            fetch(indexServerUrl + "/logistics-objects")
                .then(response => response.json())
                .then(data => {
                    setData(data);
                }).catch(error => {
                    console.log(error);
                });
        }
        fetchData();
        const intervalId = setInterval(() => {
            fetchData()
        }, 1000 * 2) // in milliseconds
        return () => clearInterval(intervalId)
    }, []);

    useEffect(() => {
        var logisticsObjectTypeCounter = {
            "https://onerecord.iata.org/ns/cargo#Waybill": 0,
            "https://onerecord.iata.org/ns/cargo#Shipment": 0,
            "https://onerecord.iata.org/ns/cargo#Piece": 0,
            "https://onerecord.iata.org/ns/cargo#UnitComposition": 0,
            "https://onerecord.iata.org/ns/cargo#Uld": 0,
            "https://onerecord.iata.org/ns/cargo#Loading": 0,
            "https://onerecord.iata.org/ns/cargo#TransportMeans": 0,
            "https://onerecord.iata.org/ns/cargo#TransportMovement": 0,
        }

        var logisticsObjectURIlogisticsObjectTypeMap = {};

        //generate nodes
        if (data.length < nodes.length) {
            setNodes(initNodes);

        }

        data.map((logisticsObject) => {
            logisticsObjectURIlogisticsObjectTypeMap[logisticsObject["@id"]] = logisticsObject["@type"];
            if (logisticsObjectTypes.includes(logisticsObject["@type"])) {
                let offset = 50;
                let parentNode = "physics";
                let xCoordinate = 30 + (logisticsObjectTypes.indexOf(logisticsObject["@type"]) * xSpacing);
                if (logisticsObject["@type"] === "https://onerecord.iata.org/ns/cargo#Waybill") {
                    parentNode = "contractuals";
                    xCoordinate = 30;
                } else if (logisticsActivityTypes.includes(logisticsObject["@type"])) {
                    parentNode = "activities";
                }
                if (logisticsObjectTypeCounter[logisticsObject["@type"]] >= 5) {
                    return;
                }
                var yCoordinate = offset + (100 * logisticsObjectTypeCounter[logisticsObject["@type"]]);


                setNodes((ns) => ns.concat({
                    id: logisticsObject["@id"],
                    type: 'custom',
                    data: {
                        logisticsObjectType: logisticsObject["@type"].split("#").pop(),
                        logisticsObjectId: logisticsObject["@id"].split("/logistics-objects/").pop(),
                        color: getColorByLogisticsObjectURI(logisticsObject["@id"])
                    },
                    position: { x: xCoordinate, y: yCoordinate },
                    parentNode: parentNode
                }));
                logisticsObjectTypeCounter[logisticsObject["@type"]] += 1;
            }
            else if (logisticsObject["@type"] === "https://onerecord.iata.org/ns/cargo#LogisticsEvent") {
                setNodes((ns) => ns.concat({
                    id: logisticsObject["@id"],
                    type: 'logisticsEvent',
                    data: { logisticsObjectType: logisticsObject["@type"].split("#").pop(), logisticsObjectId: logisticsObject["@id"].split("/logistics-objects/").pop() },
                    position: { x: 0, y: 0 },
                }));
            }
            return logisticsObject;
        });

        data.map((logisticsObject) => {
            (async () => {
                const nquads = await jsonld.toRDF(logisticsObject, { format: 'application/n-quads' });
                // for each nquad, split into subject, predicate, object, graph
                nquads.split("\n").forEach((nquad) => {
                    const [subject, predicate, o] = nquad.split(" ");
                    if (predicate !== undefined && subject !== undefined && o !== undefined && subject.indexOf("/logistics-objects/") > 0 && o.indexOf("/logistics-objects/") > 0) {
                        let linkId = subject.replaceAll("<", "").replaceAll(">", "") + "_" + o.replaceAll("<", "").replaceAll(">", "");
                        let linkIdReverse = o.replaceAll("<", "").replaceAll(">", "") + "_" + subject.replaceAll("<", "").replaceAll(">", "");

                        if (!links.includes(linkId) && !links.includes(linkIdReverse)) {
                            let node1 = logisticsObjectURIlogisticsObjectTypeMap[subject.replaceAll("<", "").replaceAll(">", "")];
                            let node2 = logisticsObjectURIlogisticsObjectTypeMap[o.replaceAll("<", "").replaceAll(">", "")];
                            if (
                                (node1 === "https://onerecord.iata.org/ns/cargo#Waybill" && node2 === "https://onerecord.iata.org/ns/cargo#Shipment")
                                
                            ) {
                                setEdges((eds) => eds.concat({
                                    id: linkId,
                                    source: subject.replaceAll("<", "").replaceAll(">", ""),
                                    sourceHandle: "sourceTop",                                    
                                    target: o.replaceAll("<", "").replaceAll(">", ""),
                                    targetHandle: "targetLeft",
                                    className: "smoothstep",
                                }));
                            } else if ((node1 === "https://onerecord.iata.org/ns/cargo#Shipment" && node2 === "https://onerecord.iata.org/ns/cargo#Waybill")) {
                                setEdges((eds) => eds.concat({
                                    id: linkId,
                                    source: subject.replaceAll("<", "").replaceAll(">", ""),
                                    sourceHandle: "sourceLeft",                                    
                                    target: o.replaceAll("<", "").replaceAll(">", ""),
                                    targetHandle: "targetTop",
                                    className: "smoothstep",
                                }));
                            
                            } else if (logisticsObjectTypes.indexOf(node1) < logisticsObjectTypes.indexOf(node2)) {
                                setEdges((eds) => eds.concat({
                                    id: linkId,
                                    source: subject.replaceAll("<", "").replaceAll(">", ""),
                                    target: o.replaceAll("<", "").replaceAll(">", ""),
                                }));
                            } else {
                                setEdges((eds) => eds.concat({
                                    id: linkId,
                                    source: o.replaceAll("<", "").replaceAll(">", ""),
                                    target: subject.replaceAll("<", "").replaceAll(">", ""),
                                }));
                            }
                            setLinks(links => [...links, linkId]);
                        }
                    }
                })

            })();
            return logisticsObject;
        })
    }, [data]);




    return (
        <div style={{ height: '92vh' }} className={"relative"}>

            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                nodeTypes={nodeTypes}
                panOnDrag={true}
                nodesDraggable={false}
                fitView={true}
                onNodeClick={onNodeClick}

                defaultViewport={{ x: window.innerWidth / 4, y: window.innerHeight / 7, zoom: window.innerWidth / window.innerHeight / 3 }}
                attributionPosition="top-right"
                className={"bg-white"}
            >
                <Controls />
            </ReactFlow>

        </div>
    );
}

export default LiveViewer;
