import data_step0 from './data/data_step0.json';
import data_step1 from './data/data_step1.json';
import data_step2 from './data/data_step2.json';
import data_step3 from './data/data_step3.json';
import data_step4 from './data/data_step4.json';
import data_step5 from './data/data_step5.json';
import data_step6 from './data/data_step6.json';
import data_step7 from './data/data_step7.json';
import data_step8 from './data/data_step8.json';

var Scenario2 =
{
  "name": "Air Cargo Export & Import",
  "description": "Air Cargo Process starting at Airline's Warehouse",
  "steps":
  {
    1: {
      "title": "ULD & TransportMeans are already created",
      "description": "Shipper creates pieces",
      "data": data_step0,
    },
    2: {
      "title": "Shipper creates pieces",
      "description": "Shipper creates pieces",
      "data": data_step1,
    },
    3: {
      "title": "Forwarder consolidates pieces to shipment",
      "description": "Forwarder consolidates pieces to shipment",
      "data": data_step2,
    },
    4: {
      "title": "Forwarder creates Waybill for shipment",
      "description": "Forwarder creates Waybill for shipmentt",
      "data": data_step3,
    },
    5: {
      "title": "GHA/Airline builds up ULD with pieces",
      "description": "GHA builds up ULD with pieces",
      "data": data_step4,
    },
    6: {
      "title": "GHA/Airline loads ULD on aircraft",
      "description": "GHA/Airline loads ULD on aircraft",
      "data": data_step5,
    },
    7: {
      "title": "Airline moves ULD on aircraft",
      "description": "Airline moves ULD on aircraft",
      "data": data_step6,
    },
    8: {
      "title": "GHA/Airline unloads ULD from aircraft",
      "description": "GHA/Airline unloads ULD from aircraft",
      "data": data_step7,
    },
    9: {
      "title": "GHA/Airline breaks down ULD",
      "description": "GHA/Airline breaks down ULD",
      "data": data_step8,
    },
  }
}

export default Scenario2;