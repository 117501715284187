import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";
import ScenarioViewer from "./ScenarioViewer";
import LiveViewer from "./LiveViewer";
import { ReactFlowProvider } from "reactflow";
import Demos from "./components/Demos";
import Home from "./components/Home";
import { useEffect, useState } from "react";
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas, faHouse } from '@fortawesome/free-solid-svg-icons';
import LogisticsObjects from "./components/LogisticsObjects";
import LogisticsObjectDetails from "./components/LogisticsObjectDetails";
import LogisticsObjectForm from "./components/CreateLogisticsObject/LogisticsObjectForm";

library.add(fas, faHouse);

export default function App() {
  const [selectedRole, setSelectedRole] = useState(null);
  const [roleColor, setRoleColor] = useState("blue");

  useEffect(() => {
    if (selectedRole === null) {
        localStorage.getItem("selectedRole") && setSelectedRole(localStorage.getItem("selectedRole"));
    } else {
        localStorage.setItem("selectedRole", selectedRole);
    }
}, [selectedRole]);


  return (
    <ReactFlowProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout selectedRole={selectedRole} roleColor={roleColor} />}>
          <Route index element={<Home selectedRole={selectedRole} setSelectedRole={setSelectedRole} setRoleColor={setRoleColor} />} /> 
          <Route path="demos/:scenarioId" element={<ScenarioViewer />} />                   
          <Route path="demos" element={<Demos />} />                   
          <Route path="logistics-objects/create/:logisticsObjectType" element={<LogisticsObjectForm selectedRole={selectedRole} />} />          
          <Route path="logistics-objects/details" element={<LogisticsObjectDetails />} />
          <Route path="logistics-objects" element={<LogisticsObjects selectedRole={selectedRole} />} />
          <Route path="live" element={<LiveViewer />} />                   
        </Route>
      </Routes>
    </BrowserRouter>
    </ReactFlowProvider>
  );
}