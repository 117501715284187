import Node from './Node';

export default function JSONLDTable({ data }) {

  return (
    <div className="text-sm grid grid-cols-5">
      {Object.keys(data).map((key, i) => (
        <>
        <div key={i} className={`p-4 ${i % 2 !== 0 ? "bg-white" : "bg-gray-50"} hover:bg-gray-100 border-gray-100 border col-span-5 flex`}>          
            <div className='w-32 md:w-64'>{key.split("#").pop()}</div>                    
            <Node data={data[key]} />
          </div>
          </>
      ))}


    </div>




  );
}
