// https://onerecord.iata.org/ns/cargo#VolumetricWeight
import Value from "./Value";

export default function VolumetricWeight({ key, data }) {
  const template = [];
  if ("https://onerecord.iata.org/ns/cargo#chargeableWeight" in data) {
    template.push(
      <span>
        <span style={{ paddingRight: "5px" }}>
          {"https://onerecord.iata.org/ns/cargo#chargeableWeight"
            .split("#")
            .pop()}:
        </span>
        <Value
          data={data["https://onerecord.iata.org/ns/cargo#chargeableWeight"]}
        />
      </span>
    );
  }
  if (template.length > 0) {
    template.push(<br />);
  }
  if ("https://onerecord.iata.org/ns/cargo#conversionFactor" in data) {
    template.push(
      <span>
        <span style={{ paddingRight: "5px" }}>
          {"https://onerecord.iata.org/ns/cargo#conversionFactor"
            .split("#")
            .pop()}
        </span>
        <Value
          data={data["https://onerecord.iata.org/ns/cargo#conversionFactor"]}
        />
      </span>
    );
  }


  return <>{template}</>;
}
