import { useEffect, useState } from "react";
import { Button, Checkbox, Label, TextInput, Textarea } from 'flowbite-react';
import { getDataOwnerNameByLogisticsObjectURI } from "../../utils";


export default function ShipmentForm(props) {

    const type = "https://onerecord.iata.org/ns/cargo#Shipment";

    const [id, setId] = useState("");
    const [goodsDescription, setGoodsDescription] = useState("");
    const [totalGrossWeight, setTotalGrossWeight] = useState("");
    const [shipmentOfPieces, setShipmentOfPieces] = useState([])

    const [idColor, setIdColor] = useState(null);
    const [goodsDescriptionColor, setGoodsDescriptionColor] = useState(null);
    const [totalGrossWeightColor, setTotalGrossWeightColor] = useState(null);
    const [shipmentOfPiecesColor, setShipmentOfPiecesColor] = useState(null);

    const [availablePieces, setAvailablePieces] = useState(null);


    const [error, setError] = useState(null);

    useEffect(() => {
        let logisticsObject = {
            "@type": type,
            "@id": id,
            "goodsDescription": goodsDescription,
            "totalGrossWeight": totalGrossWeight,
            "shipmentOfPieces": shipmentOfPieces,
        };
        props.setLogisticsObject(logisticsObject);
        props.setJsonLD(logisticsObject);
    }, [id, goodsDescription, totalGrossWeight, shipmentOfPieces]);

    useEffect(() => {
        if (availablePieces === null) {
            fetch("https://index.one-record.de/logistics-objects?logisticsObjectTypes=https%3A%2F%2Fonerecord.iata.org%2Fns%2Fcargo%23Piece", {
                method: "GET"
            })
                .then(response => response.json())
                .then(data => {
                    setAvailablePieces(data);
                }
                );
        }

    }, [availablePieces]);



    return (
        <>
            <form className="flex max-w-md flex-col gap-4">
                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="id"
                            value="ID*"
                        />
                    </div>
                    <TextInput
                        id="id"
                        helperText={<>This MUST be a unique id, allowed characters: a-z, 0-9, -</>}
                        required
                        shadow
                        type="text"
                        value={id}
                        color={idColor}
                        onChange={(e) => {
                            e.preventDefault();
                            //replace uppercase with lowercase
                            let sanitized = e.target.value.toLowerCase();
                            // replace whitespace with dash     
                            sanitized = sanitized.replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '');
                            setId(sanitized);
                        }}
                        onBlur={(e) => {
                            e.preventDefault();
                            // if multiple dashes in a row, replace with single dash
                            let sanitized = e.target.value.replace(/-+/g, '-');
                            //replace last dash with whitespace                            
                            sanitized = sanitized.replace(/-$/, ' ');
                            setId(sanitized);
                        }}
                    />
                </div>

                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="pieces" value="Pieces*"/>
                    </div>

                    {availablePieces !== null && availablePieces.length === 0 && <div className="text-red-600 text-sm">No pieces found.</div>}

                    {availablePieces?.map((piece) => (
                        <div key={piece["@id"]} className="flex items-center gap-2">
                            <Checkbox id={piece["@id"]} onChange={(e) => {
                                if (e.target.checked) {
                                    setShipmentOfPieces([...shipmentOfPieces, piece["@id"]]);
                                } else {
                                    setShipmentOfPieces(shipmentOfPieces.filter((item) => item !== piece["@id"]));
                                }
                            }}
                            />
                            <Label htmlFor={piece["@id"]}>
                                {piece["@id"].split("/logistics-objects/").pop()} @ {getDataOwnerNameByLogisticsObjectURI(piece["@id"])}
                            </Label>
                        </div>
                    ))}
                    {shipmentOfPiecesColor !== null && <div className="text-red-600 text-sm">Select at least one piece.</div>}
                </div>


                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="goodsDescription" value="Goods description"
                        />
                    </div>
                    <Textarea
                        id="goodsDescription"
                        type="text"
                        shadow
                        rows={3}
                        required
                        value={goodsDescription}
                        color={goodsDescriptionColor}
                        onChange={(e) => {
                            setGoodsDescription(e.target.value);
                        }}
                    />
                </div>

                <div>
                    <div className="mb-2 block">
                        <Label
                            htmlFor="totalGrossWeight"
                            value="Total gross weight (in kilogram)*"
                        />
                    </div>
                    <TextInput
                        id="totalGrossWeight"
                        helperText={<>This MUST be a decimal number</>}
                        shadow
                        required
                        color={totalGrossWeightColor}
                        type="number"
                        step={0.01}
                        pattern={"[0-9]+([\.][0-9]+)?"}
                        value={totalGrossWeight}
                        onChange={(e) => {
                            if(e.target.value === "") {
                                setTotalGrossWeight("");
                                return;
                            }
                            // only allow numbers and one decimal point
                            let sanitized = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');                            
                            setTotalGrossWeight(sanitized);
                        }}
                        onBlur={(e) => {
                            if(e.target.value === "") {
                                setTotalGrossWeight("");
                                return;
                            }
                            // reduce to two decimal points
                            let sanitized = parseFloat(e.target.value).toFixed(2).toString();
                            setTotalGrossWeight(sanitized);
                        }}
                    />
                </div>
                {error && <div className="text-red-600 text-sm">{error}</div>}

                <button disabled={availablePieces === null || availablePieces.length === 0} type={"submit"} onClick={(e) => {
                    e.preventDefault();
                    if (id === "" || totalGrossWeight === "" || shipmentOfPieces.length === 0) {
                        if (id === "") {
                            setIdColor("failure");                            
                        }
                        if (totalGrossWeight === "") {
                            setTotalGrossWeightColor("failure");
                        }
                        if (shipmentOfPieces.length === 0) {
                            setShipmentOfPiecesColor("failure");
                        }
                        setError("Please fill in all required");
                    } else {
                        setIdColor(null);
                        setTotalGrossWeightColor(null);
                        setShipmentOfPiecesColor(null);
                        setError(null);

                        props.createLogisticsObject().then((result) => {
                            if (result !== "Error") {
                                setId("");
                                setGoodsDescription("");
                                setTotalGrossWeight("");
                                setShipmentOfPieces([]);
                            }
                        });
                    }
                }}
                    className="rounded-md bg-gray-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                    Create
                </button>
            </form>




        </>

    );
}
