import { Outlet, Link } from "react-router-dom";
import Navbar from "./components/Navbar";

const Layout = (props) => {

  return (
    <>
      <Navbar selectedRole={props?.selectedRole} advancedModeOn={props?.advancedModeOn}/>
      <Outlet />
      <div className="hidden">
        Color palette
        <div className="text-red-600 bg-red-600 border-red-600"></div>
        <div className="text-blue-600 bg-blue-600 border-blue-600"></div>
        <div className="text-gray-600 bg-gray-600 border-gray-600"></div>
        <div className="text-orange-600 bg-orange-600 border-orange-600"></div>
        <div className="text-cyan-600 bg-cyan-600 border-cyan-600"></div>
        
        <div className="text-red-700 bg-red-700 border-red-700"></div>
        <div className="text-blue-700 bg-blue-700 border-blue-700"></div>
        <div className="text-gray-700 bg-gray-700 border-gray-700"></div>
        <div className="text-orange-700 bg-orange-700 border-orange-700"></div>
        <div className="text-cyan-700 bg-cyan-700 border-cyan-700"></div>

        <div className="text-red-800 bg-red-800 border-red-800"></div>
        <div className="text-blue-800 bg-blue-800 border-blue-800"></div>
        <div className="text-gray-800 bg-gray-800 border-gray-800"></div>
        <div className="text-orange-800 bg-orange-800 border-orange-800"></div>
        <div className="text-cyan-800 bg-cyan-800 border-cyan-800"></div>

        <div className="text-red-900 bg-red-900 border-red-900"></div>
        <div className="text-blue-900 bg-blue-900 border-blue-900"></div>
        <div className="text-gray-900 bg-gray-900 border-gray-900"></div>
        <div className="text-orange-900 bg-orange-900 border-orange-900"></div>
        <div className="text-cyan-900 bg-cyan-900 border-cyan-900"></div>

        
        
        
        

      </div>
    </>


  )
};

export default Layout;