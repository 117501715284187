import { Link } from "react-router-dom";

export default function Demos(props) {
    return (
        <div className="pt-16 pb-32 flex content-center items-center justify-center">
            <div>
                <div className="text-center">
                    <h1 className="font-bold text-xl">Demos</h1>
                    <br />
                    <p className="font-semibold">Please select one of the following demos</p>
                    <br />
                </div>
                <div className="justify-self-auto">
                    <ul className="list-inside list-disc">
                        <li className="list-item"><Link to={`/demos/scenario1`}>Air Cargo Export</Link></li>
                        <li><Link to={`/demos/scenario2`}>Air Cargo Export & Import</Link></li>                        
                    </ul>
                </div>
            </div>
        </div>

    );
}
