import config from './config.json';

const isValidUrl = urlString => {
    try {
        return Boolean(new URL(urlString));
    }
    catch (e) {
        return false;
    }
}

export function getColorByLogisticsObjectURI(uri) {
    let companies = config.companies;
    let color = "blue";
    //check if uri is a valid uri and uri contains "logisticsObjects"
    if (uri && isValidUrl(uri) && uri.includes("/logistics-objects/")) {
        companies.forEach(company => {
            if (uri.includes(company.server)) {
                color = company.color;
            }
        })
    }
    return color;
}

export function getDataOwnerNameByLogisticsObjectURI(uri) {
    let companies = config.companies;
    let name = "unknown"
    //check if uri is a valid uri and uri contains "logisticsObjects"
    if (uri && isValidUrl(uri) && uri.includes("/logistics-objects/")) {
        companies.forEach(company => {
            if (uri.includes(company.server)) {
                name = company.name;
            }
        })
    }
    return name;
}

export function getColorByRole(role) {
    let color = "blue";
    let companies = config.companies;
    companies.forEach(company => {
        if (role === company.role) {
            color = company.color;
        }
    });
    return color;
}

export function isAdvancedModeEnabled() {
    return localStorage.getItem("advancedModeOn") === "true";
}

export function enableAdvancedMode() {
    localStorage.setItem("advancedModeOn", true);
}

export function disableAdvancedMode() {
    localStorage.setItem("advancedModeOn", false);
}


export function getSelectedRole() {
    return localStorage.getItem("selectedRole");
}

export function getServerByRole(role) {
    let companies = config.companies;
    let server = "";
    companies.forEach(company => {
        if (role === company.role) {
            server = company.server;
        }
    });
    return server;
}