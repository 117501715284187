import Node from "./LinkedDataViewer/Node";

function LogisticsEventTable(props) {
  const logisticsEvents = props.data;
  return (
    <div className="grid text-sm">
      {logisticsEvents.map((item, i) => (
        <div className={`grid grid-cols-5 p-4 ${i % 2 !== 0 ? "bg-white" : "bg-gray-50"} hover:bg-gray-100 border-gray-100 border`} key={i}>
          {Object.keys(item).map((key, index) => (
            <>
              <div className="col-span-1 w-32 md:w-64">              
                {key.split("#").pop()}
              </div>
              <div className="col-span-4">

                <Node data={item[key]} />
              </div>
            </>
          ))}

        </div>
      ))}
    </div>
  );
}
LogisticsEventTable.propTypes = {};
export default LogisticsEventTable;