// https://onerecord.iata.org/ns/cargo#ActivitySequence

import LogisticsObject from "./LogisticsObject";

export default function ActivitySequence({ data }) {
  const template = [];

  if ("https://onerecord.iata.org/ns/cargo#sequenceNumber" in data) {
    template.push(
      <span>#{data["https://onerecord.iata.org/ns/cargo#sequenceNumber"]} </span>
    );
  }
  if ("https://onerecord.iata.org/ns/cargo#activity" in data) {
    template.push(
      <LogisticsObject
        data={data["https://onerecord.iata.org/ns/cargo#activity"]}
      />
    );
  }

  return <>{template}</>;
}
