import { useParams } from "react-router-dom";
import PieceForm from "./PieceForm";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactJson from "react-json-view";
import { getSelectedRole, getServerByRole, isAdvancedModeEnabled } from "../../utils";
import ShipmentForm from "./ShipmentForm";
import WaybillForm from "./WaybillForm";
import UnitCompositionForm from "./UnitCompositionForm";
import LoadingForm from "./LoadingForm";
import TransportMovementForm from "./TransportMovementForm";


export default function LogisticsObjectForm(props) {
    const logisticsObjectPOSTEndpoint = getServerByRole(getSelectedRole()) + "/logistics-objects";
    const { logisticsObjectType } = useParams();
    const [logisticsObject, setLogisticsObject] = useState(null);
    const [jsonLdData, setJsonLdData] = useState(null);

    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    const [responseCode, setResponseCode] = useState(null);
    const [responseMessage, setResponseMessage] = useState(null);
    const [location, setLocation] = useState(null);


    async function createLogisticsObject() {
        var result = null;
        setError(null);
        setSuccess(null);
        setResponseMessage(null);
        setResponseCode(null);
        if (logisticsObject === null || logisticsObject["@id"] === undefined || logisticsObject["@id"] === null || logisticsObject["@id"] === "") {
            setError("ID is required");
            return "Error";
        }
        result = await fetch(logisticsObjectPOSTEndpoint, {
            method: 'POST',
            body: JSON.stringify(jsonLdData),
            headers: { 'Content-Type': 'application/ld+json' },
        })
            .then(res => {
                setResponseCode(res.status);
                if (!res.ok) {
                    setSuccess(null);
                    if (res.status === 409) {
                        setError("ID already exists");
                    } else {
                        setError("Error creating Logistics Object");
                    }
                }
                if (res.status === 201) {
                    setLocation(res.headers.get("Location"));
                    setError(null);
                    setSuccess("Logistics Object created successfully. URL: " + res.headers.get("Location"));
                }
                return res.json();
            }).then((data) => {
                setResponseMessage(data);
                return "Error";
            }
            ).catch(error => {
                console.log(error);
            });
        return result;
    }

    const setJsonLD = (logisticsObject) => {

        let result = {}
        Object.keys(logisticsObject).forEach(key => {
            if (logisticsObject[key] === undefined || logisticsObject[key] === null || logisticsObject[key] === "") {
                return;
            }
            if (key === "@type") {
                result["@type"] = logisticsObject[key];
            } else if (key === "@id") {
                result["@id"] = logisticsObjectPOSTEndpoint + "/" + logisticsObject[key];
            } else if (key.includes("damageFlag")) {
                result[`https://onerecord.iata.org/ns/cargo#` + key] =
                {
                    "@type": "http://www.w3.org/2001/XMLSchema#boolean",
                    "@value": logisticsObject[key].toString()
                };
            } else if (key.includes("numberOfDoors")) {
                result[`https://onerecord.iata.org/ns/cargo#` + key] =
                {
                    "@type": "http://www.w3.org/2001/XMLSchema#integer",
                    "@value": logisticsObject[key].toString()
                };
            } else if (key.includes("tareWeight") || key.includes("grossWeight") || key.includes("netWeight") || key.includes("totalGrossWeight")) {
                result[`https://onerecord.iata.org/ns/cargo#` + key] = {
                    "@type": "https://onerecord.iata.org/ns/cargo#Value",
                    "https://onerecord.iata.org/ns/cargo#numericalValue": {
                        "@type": "http://www.w3.org/2001/XMLSchema#double",
                        "@value": parseFloat(logisticsObject[key]).toString()
                    },
                    "https://onerecord.iata.org/ns/cargo#unit": "KGM"
                };
            } else if (key.includes("composedPieces") || key.includes("shipmentOfPieces") || key.includes("loadedUnits")) {
                let composedPieces = [];
                logisticsObject[key].forEach(piece => {
                    composedPieces.push({ "@id": piece });
                });
                if (composedPieces.length > 0)
                    result[`https://onerecord.iata.org/ns/cargo#` + key] = composedPieces;            
            } else if (
                key.includes("shipment") || 
                key.includes("loadingUnit") || 
                key.includes("departureLocation") || 
                key.includes("arrivalLocation") || 
                key.includes("onTransportMeans") ||
                key.includes("operatingTransportMeans")
                ) {
                result[`https://onerecord.iata.org/ns/cargo#` + key] = { "@id": logisticsObject[key] };            
            } else {
                result[`https://onerecord.iata.org/ns/cargo#` + key] = logisticsObject[key];
            }
        });

        setJsonLdData(result);
    }
    return (
        <>
            <div className="mx-auto w-2/3">

                {error !== null && (
                    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
                        <strong className="font-bold pr-2">Error</strong>
                        <span className="block sm:inline">{error}</span>
                    </div>
                )}
                {success !== null && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                        <strong className="font-bold pr-2">Success</strong>
                        <span className="block sm:inline">{success}</span>
                    </div>
                )}
            </div>

            <div className="md:flex pt-2">
                <div className="mx-auto px-2">


                    {logisticsObjectType.toLowerCase() === "piece" && (<><h1 className="text-lg pb-3">Create Piece</h1><PieceForm setJsonLD={setJsonLD} setLogisticsObject={setLogisticsObject} createLogisticsObject={createLogisticsObject} /></>)}
                    {logisticsObjectType.toLowerCase() === "shipment" && (<><h1 className="text-lg pb-3">Create Shipment</h1><ShipmentForm setJsonLD={setJsonLD} setLogisticsObject={setLogisticsObject} createLogisticsObject={createLogisticsObject} /></>)}
                    {logisticsObjectType.toLowerCase() === "waybill" && (<><h1 className="text-lg pb-3">Create Waybill</h1><WaybillForm setJsonLD={setJsonLD} setLogisticsObject={setLogisticsObject} createLogisticsObject={createLogisticsObject} /></>)}
                    {logisticsObjectType.toLowerCase() === "unit-composition" && (<><h1 className="text-lg pb-3">Create Unit Composition (Activity)</h1><UnitCompositionForm setJsonLD={setJsonLD} setLogisticsObject={setLogisticsObject} createLogisticsObject={createLogisticsObject} /></>)}
                    {logisticsObjectType.toLowerCase() === "loading" && (<><h1 className="text-lg pb-3">Create Loading (Activity)</h1><LoadingForm setJsonLD={setJsonLD} setLogisticsObject={setLogisticsObject} createLogisticsObject={createLogisticsObject} /></>)}
                    {logisticsObjectType.toLowerCase() === "transport-movement" && (<><h1 className="text-lg pb-3">Create Transport Movement (Activity)</h1><TransportMovementForm setJsonLD={setJsonLD} setLogisticsObject={setLogisticsObject} createLogisticsObject={createLogisticsObject} /></>)}
                    
                    
                    <div className="text-gray-400">* required</div>
                </div>

                {isAdvancedModeEnabled() && (
                    <>
                        <div className="max-w-md md:max-w-none w-full md:w-1/2 pt-10 px-2 mr-10 md:pt-0">
                            <h1 className="text-lg pb-5"><FontAwesomeIcon icon={["fas", "arrow-right"]} className="pr-3" />HTTP Request</h1>
                            <div className="border-gray-300 border p-3 text-sm">
                                POST /logistics-objects HTTP/1.1<br />
                                Host: {logisticsObjectPOSTEndpoint.split("/logistics-objects")[0].replace("https://", "").replace("http://", "")}<br />
                                Content-Type: application/ld+json<br /><br />
                                <br />
                                {jsonLdData && (
                                    <div className="">
                                        <ReactJson enableClipboard={false} style={{ backgroundColor: 'rgb(0 0 0 / 1%)', border: '0.5px solid rgb(222, 226, 230)', padding: '15px' }} iconStyle="square" src={jsonLdData} name={null} displayArrayKey={false} displayObjectSize={false} displayDataTypes={false} />

                                    </div>
                                )}
                            </div>

                            {responseCode && (
                                <>
                                    <h1 className="text-lg pt-5 pb-5"><FontAwesomeIcon icon={["fas", "arrow-left"]} className="pr-3" />HTTP Response</h1>
                                    <div className="border-gray-300 border p-3 text-sm">
                                        HTTP/1.1 {responseCode}<br />
                                        {location && (<>Location: {location}<br /></>)}
                                        {responseMessage !== null && (<>
                                            <br />
                                            <br />

                                            <div className="">
                                                <ReactJson enableClipboard={false} style={{ backgroundColor: 'rgb(0 0 0 / 1%)', border: '0.5px solid rgb(222, 226, 230)', padding: '15px' }} iconStyle="square" src={responseMessage} name={null} displayArrayKey={false} displayObjectSize={false} displayDataTypes={false} />

                                            </div>
                                        </>
                                        )}
                                    </div>
                                </>)}
                        </div>
                    </>
                )}
            </div>


        </>
    )


}