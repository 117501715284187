import { Link } from "react-router-dom";
import { getSelectedRole } from "../utils";

const Home = (props) => {

  return (
    <div className="container mx-auto p-4">
      <div className="pt-16 pb-32">
        <div className="">
          <h1 className="font-bold text-xl pb-3">Welcome to ONE Record Demo</h1>

          <p className="pb-5 ">This is a ONE Record demo environment to get familiar with ONE Record's data model, learn the data types and simulate interactions, and processes with ONE Record.</p>

          <div>
            <p className="font-semibold">1. Select your role</p>
            <br />
            <div className="flex space-x-3 text-sm">
              <button onClick={() => { props.setSelectedRole("Shipper") }} className=
                {`${props?.selectedRole === "Shipper" ? "bg-gray-600 text-white border-gray-600" : "bg-white border-gray-600 hover:bg-gray-500 hover:text-white"} hover:border-gray-700 font-bold py-2 px-4 rounded border`}>
                Shipper (e.g. Apple)
              </button>

              <button onClick={() => { props.setSelectedRole("Forwarder") }} className=
                {`${props?.selectedRole === "Forwarder" ? "bg-red-600 text-white border-red-600" : "bg-white border-red-600 hover:bg-red-500 hover:text-white"} hover:border-red-700 font-bold py-2 px-4 rounded border`}>
                Forwarder (e.g. DB Schenker)
              </button>

              <button onClick={() => { props.setSelectedRole("Carrier") }} className=
                {`${props?.selectedRole === "Carrier" ? "bg-blue-600 text-white border-blue-600" : "bg-white border-blue-600 hover:bg-blue-500 hover:text-white"} hover:border-blue-700 font-bold py-2 px-4 rounded border`}>
                Carrier (e.g. LH Cargo)
              </button>
            </div>
            <p className="pt-2">(You can change your role at any time.)</p>
            {(props?.selectedRole !== undefined && props?.selectedRole !== null) && (
              <>
                <p className="font-semibold pt-8">2. Create and view data in ONE Record</p>
                <br />

                <ul className="list-disc list-inside">
                  <li><Link to={`/logistics-objects`}>Browse existing ONE Record data</Link></li>
                  or
                  <li>Create new data in ONE Record<br /><br />
                    <div className="flex space-x-2">
                      {props?.selectedRole === "Shipper" && (
                        <>
                          <Link to={`/logistics-objects/create/piece`}><button className="rounded-md bg-gray-600 px-3.5 py-2.5 text-xs font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600">
                            Create Piece
                          </button></Link>
                        </>
                      )}
                      {props?.selectedRole === "Forwarder" && (
                        <>
                          <Link to={`/logistics-objects/create/shipment`}><button className="rounded-md bg-red-600 px-3.5 py-2.5 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                            Create Shipment
                          </button></Link>
                          <Link to={`/logistics-objects/create/waybill`}><button className="rounded-md bg-red-600 px-3.5 py-2.5 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                            Create Waybill
                          </button></Link>
                        </>)}
                      {props?.selectedRole === "Carrier" && (
                        <>
                          <Link to={`/logistics-objects/create/unit-composition`}><button className="rounded-md bg-blue-600 px-3.5 py-2.5 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                            Create Unit Composition (Activity)
                          </button></Link>
                          <Link to={`/logistics-objects/create/loading`}><button className="rounded-md bg-blue-600 px-3.5 py-2.5 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                            Create Loading (Activity)
                          </button></Link>
                          <Link to={`/logistics-objects/create/transport-movement`}><button className="rounded-md bg-blue-600 px-3.5 py-2.5 text-xs font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
                            Create Transport Movement (Activity)
                          </button></Link>
                        </>
                      )}
                    </div>
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>
        <div className="text-left border-red-200 border pt-4 ps-3 mt-12">
          <h2 className="text-l font-semibold">Limitations</h2>
          <p className="pb-5 text-left">
            <ul className="list-disc list-inside">
              <li className="list-item">No bookings included</li>
              <li className="list-item">For demonstration purpose: limited number of object types</li>
              <li className="list-item">For better visualisation, the number of objects in Live View is limited to five per object type.</li>
              <li className="list-item">The stakeholder setting can vary in practice depending on the business environment</li>
              <li className="list-item">In practice, the data can also be provided by other authorised actors, e.g. a freight forwarder can host the data of a shipper.</li>
            </ul>
          </p>
        </div>
      </div>
    </div>
  );

};

export default Home;